import React, { useState } from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Grid,
  FormControlLabel,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import {
  Field,
  Form,
  ErrorMessage,
  useFormik,
  FormikProvider,
  FormikProps,
  FormikErrors,
} from 'formik';
import { FormContext } from '../../contexts/FormContext';
import { MagicTextField } from '../inputs/generic/MagicTextField';
import { MagicPhoneField } from '../inputs/generic/MagicPhoneField';
import { MagicSelect } from '../inputs/generic/MagicSelect';
import RadioGroupField from '../inputs/generic/RadioGroupField';
import { makeStyles } from '@material-ui/core';
import MagicCheckbox from '../inputs/generic/MagicCheckbox';
import userFormValidation from '../../yup/UserFormValidation';
import styleVars from '../../styleVars';
import { ReferralFormSchema, UserType } from '../../types/ReferralFormSchema';
import { UserSelection } from '../inputs/UserSelection/UserSelection';
import { UserFormSchema } from '../../types/ReferralFormSchema';
import {
  AdjusterDTO,
  CaseManagerDTO,
  PhysicianDTO,
} from '../inputs/UserSelection/UserSelectionAPI';
import { wakeupPdfLambda } from '../../helpers/pdfWakeup';
import { getPrefillFieldsFromUser } from '../../helpers/prefill';

enum UserNewOrExisting {
  New = 'new',
  Existing = 'existing',
}

const useStyles = makeStyles({
  link: {
    fontSize: styleVars.fontSizeLarge,
    color: '#0A79CE',
    textDecoration: 'none',
    '&:visited': {
      color: '#0A79CE',
    },
    '&:hover, &:active': {
      color: '#0A79CE',
      textDecoration: 'none',
    },
  },
  leftAlign: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  divider: {
    borderTop: '1px solid black',
  },
});

const UserForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fetchedMatches, setFetchedMatches] = useState<
    AdjusterDTO[] | CaseManagerDTO[] | PhysicianDTO[]
  >([]);
  const [allowNext, setAllowNext] = useState(false);

  const { formValues, setFormValues } = useContext(FormContext);

  const formik: FormikProps<ReferralFormSchema> = useFormik({
    initialValues: {
      formStep: Number(formValues?.formStep) > 1 ? formValues?.formStep : 1,
      userFirstName: formValues?.userFirstName,
      userLastName: formValues?.userLastName,
      userEmail: formValues?.userEmail,
      userCompany: formValues?.userCompany,
      customUserInfo: !!formValues?.customUserInfo,
      userPhonePreferred: !!formValues?.userPhonePreferred,
      userEmailPreferred: !!formValues?.userEmailPreferred,
      userPhoneNumber: formValues?.userPhoneNumber,
      userPhoneType: formValues?.userPhoneType,
      userPhoneExtension: formValues?.userPhoneExtension,
      userType: formValues?.userType || null,
      userRememberMe:
        typeof formValues?.userRememberMe === 'boolean'
          ? formValues?.userRememberMe
          : true,
      userNewOrExisting: formValues?.userNewOrExisting,
      userAdjuster: formValues?.userAdjuster,
      userReferringPhysician: formValues?.userReferringPhysician,
      adjusterEmailSearch: formValues?.adjusterEmailSearch,
      caseManagerEmailSearch: formValues?.caseManagerEmailSearch,
      userCaseManager: formValues?.userCaseManager,
      physicianPhoneSearch: formValues?.physicianPhoneSearch || '',
      physicianFaxSearch: formValues?.physicianFaxSearch || '',
      physicianLastNameSearch: formValues?.physicianLastNameSearch,
      userRoleInCase: formValues?.userRoleInCase,
      UserNewOrExisting: undefined,
      emailSearch: formValues?.emailSearch || '',
    } as UserFormSchema,

    validationSchema: userFormValidation,
    onSubmit: (values) => {
      formik.setSubmitting(false);

      let prefillFields = getPrefillFieldsFromUser(values, Number(formValues.formStep));

      // Store user data in browser for restore
      if (!!values?.userRememberMe) {
        localStorage.setItem(
          'userData',
          JSON.stringify({
            userFirstName: values?.userFirstName,
            userLastName: values?.userLastName,
            userEmail: values?.userEmail,
            userCompany: values?.userCompany,
            customUserInfo: values?.customUserInfo,
            userPhonePreferred: values?.userPhonePreferred,
            userEmailPreferred: values?.userEmailPreferred,
            userPhoneNumber: values?.userPhoneNumber,
            userPhoneType: values?.userPhoneType,
            userPhoneExtension: values?.userPhoneExtension,
            userType: values?.userType,
            userRememberMe:
              typeof formValues?.userRememberMe === 'boolean'
                ? values?.userRememberMe
                : true,
            userNewOrExisting: values?.userNewOrExisting,
            userAdjuster: values?.userAdjuster,
            userReferringPhysician: values?.userReferringPhysician,
            userCaseManager: values?.userCaseManager,
            physicianPhoneSearch: values?.physicianPhoneSearch || '',
            physicianFaxSearch: values?.physicianFaxSearch || '',
            physicianLastNameSearch: values?.physicianLastNameSearch,
            userRoleInCase: values?.userRoleInCase,
          })
        );
      } else {
        localStorage.removeItem('userData');
      }
      
      // spread new properties over old values while leaving other pages alone
      setFormValues({ ...formValues, ...values, ...prefillFields });
      navigate('/referral/requiredInfo');
    },
    validateOnChange: true,
    validateOnBlur: true,
  });
  const {
    submitForm,
    values,
    setFieldValue,
    isValid,
    validateForm,
    setTouched,
  } = formik;

  const refValues = useRef(values);
  refValues.current = values;

  const clearSelectFields = () => {
    // Autocomplete fields are remove from formik.values if we set these to undefined
    setFieldValue('userAdjuster', '');
    setFieldValue('userReferringPhysician', '');
    setFieldValue('userCaseManager', '');
    setFieldValue('emailSearch', '');
    setFieldValue('physicianPhoneSearch', '');
    setFieldValue('physicianFaxSearch', '');
    setFieldValue('physicianLastNameSearch', '');
    setFetchedMatches([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    validateForm();
    setTimeout(() => {
      setAllowNext(true);
    }, 500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Exercise caution when modifying this as updating context in an unmount can cause infinite updates
  useEffect(() => {
    // When the component unmounts, update the context values with the current form values
    return () => {
      setFormValues((prevValues: ReferralFormSchema) => {
        return {
          ...prevValues,
          ...refValues.current,
        };
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formValues?.formStep) {
      setFormValues({ ...formValues, formStep: 1 });
    }
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.userType === 'other') {
      formik.setFieldValue('userNewOrExisting', 'new');
    }

    formik.validateForm();
  }, [values.userType, values.userNewOrExisting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.formStep === 5) {
      const touchAll = Object.keys(values).reduce((acc: any, key: string) => {
        acc[key] = true;
        return acc;
      }, {});
      setTouched(touchAll);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // call the API method once on mount to ensure the PDF lambda is awake
  useEffect(() => { wakeupPdfLambda() }, []); 

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate>
          <Grid item xs={12}>
            <Box style={{ width: '925px' }}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ color: '#3470A1' }}>
              Thanks for choosing to refer a patient to Bardavon
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Box height="30px"></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: styleVars.fontSizeLarge }}
            >
              * = required information
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: styleVars.fontSizeLarge,
                fontWeight: 600,
              }}
            >
              I am the*:
            </Typography>
          </Grid>
          <Grid item xs={11} style={{ marginBottom: '20px' }}>
            <Box>
              <RadioGroupField
                name="userType"
                row
                otherAction={clearSelectFields}
                options={[
                  { value: UserType.Adjuster, label: 'Adjuster' },
                  {
                    value: UserType.ReferringDoctor,
                    label: 'Referring doctor',
                  },
                  { value: UserType.CaseManager, label: 'Nurse/Case manager' },
                  { value: UserType.Other, label: 'Other' },
                ]}
              />
            </Box>
          </Grid>

          <Grid item xs={10}>
            <Box className={classes.divider} height="1px"></Box>
          </Grid>

          {values.userType && values.userType !== UserType.Other && (
            <Grid
              item
              xs={11}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <Box>
                <RadioGroupField
                  name="userNewOrExisting"
                  row
                  options={[
                    {
                      value: UserNewOrExisting.Existing,
                      label: "I've referred to Bardavon before",
                    },
                    {
                      value: UserNewOrExisting.New,
                      label: "I'm new to Bardavon",
                    },
                  ]}
                />
              </Box>
            </Grid>
          )}

          {values.userNewOrExisting === UserNewOrExisting.Existing &&
            values.userType !== UserType.Other && (
              <UserSelection
                formik={formik}
                userType={values?.userType}
                fetchedMatches={fetchedMatches}
                setFetchedMatches={setFetchedMatches}
              />
            )}
          {(values.userNewOrExisting === UserNewOrExisting.New ||
            values.userType === UserType.Other) && (
            <CustomUserInfoForm values={values} setFieldValue={setFieldValue} />
          )}
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Box height="60px"></Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={!allowNext || !isValid}
                onClick={submitForm}
              >
                <Typography variant="button">Next</Typography>
              </Button>
            </Grid>
            <Grid container spacing={2}>
              <Box height="20px"></Box>
            </Grid>

            {values.userType &&
              (values.userNewOrExisting ||
                values.userType === UserType.Other) && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '30px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <MagicCheckbox
                        name="userRememberMe"
                        setFieldValue={setFieldValue}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: styleVars.fontSizeLarge,
                            fontWeight: 600,
                          }}
                          component="span"
                        >
                          Remember me
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ fontSize: styleVars.fontSizeSmall }}
                          component="span"
                        >
                          {' '}
                          (If checked, this information will be used any time
                          you use this form)
                        </Typography>
                      </div>
                    }
                  />
                </Grid>
              )}
            <Grid item xs={2}>
              <LinearProgress
                variant="determinate"
                value={20}
                color="secondary"
                style={{ width: '145px' }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  fontWeight: 500,
                  // Not a good permanent solution for shifting the text upwards
                  lineHeight: '1px',
                  width: '145px',
                }}
              >
                (Step 1 of 5)
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

const CustomUserInfoForm = ({
  values,
  setFieldValue,
}: {
  values: UserFormSchema;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<ReferralFormSchema>>;
}) => {
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <Field
            required
            component={MagicTextField}
            name="userFirstName"
            type="text"
            label="First name"
            helperText={<ErrorMessage name="userFirstName" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            required
            component={MagicTextField}
            name="userLastName"
            type="text"
            label="Last name"
            helperText={<ErrorMessage name="userLastName" />}
          />
        </Grid>
        {values?.userType !== 'referringDoctor' && (
          <>
            <Grid item xs={6}>
              <Field
                required
                component={MagicTextField}
                name="userCompany"
                type="text"
                label="Company"
                helperText={<ErrorMessage name="userCompany" />}
              />
            </Grid>
            {values?.userType !== 'other' && <Grid item xs={6}></Grid>}
          </>
        )}
        {values?.userType === 'other' && (
          <>
            <Grid item xs={6}>
              <Field
                required
                component={MagicTextField}
                name="userRoleInCase"
                type="text"
                label="Role in case"
                helperText={<ErrorMessage name="userRoleInCase" />}
              />
            </Grid>
            {values?.userType !== 'other' &&
              values?.userType !== 'referringDoctor' && (
                <Grid item xs={6}></Grid>
              )}
          </>
        )}
        <Grid container style={{ paddingLeft: '9px' }}>
          <Typography variant="body2" style={{ color: 'gray' }}>
            Contact method (choose one*)
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Field
            required={!!values?.userEmailPreferred}
            component={MagicTextField}
            name="userEmail"
            type="text"
            label="Email"
            helperText={<ErrorMessage name="userEmail" />}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <MagicCheckbox
                name="userEmailPreferred"
                setFieldValue={setFieldValue}
                otherAction={() =>
                  values?.userPhonePreferred &&
                  setFieldValue('userPhonePreferred', false)
                }
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  color: 'gray',
                }}
              >
                Preferred method of contact
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={MagicPhoneField}
            label="Phone number"
            name="userPhoneNumber"
            value={values.userPhoneNumber}
            type="tel"
            required={!!values?.userPhonePreferred}
            InputProps={{
              onChange: (e: { target: HTMLInputElement }) =>
                setFieldValue('userPhoneNumber', e.target.value, false),
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Field
            name="userPhoneType"
            label="Type"
            id="user-phone-type-select"
            component={MagicSelect}
            items={[
              {
                name: '\0',
                value: '',
              },
              { name: 'Mobile', value: 'M' },
              { name: 'Work', value: 'W' },
            ]}
            onChange={(e: { target: HTMLInputElement }) => {
              setFieldValue('userPhoneType', e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            component={MagicTextField}
            name="userPhoneExtension"
            type="text"
            label="Extension"
            helperText={<ErrorMessage name="userPhoneExtension" />}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <MagicCheckbox
                name="userPhonePreferred"
                setFieldValue={setFieldValue}
                otherAction={() =>
                  values?.userEmailPreferred &&
                  setFieldValue('userEmailPreferred', false)
                }
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  fontSize: styleVars.fontSizeLarge,
                  color: 'gray',
                }}
              >
                Preferred method of contact
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
